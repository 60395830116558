@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --foreground-rgb: "#6d28d9";
  --background-start-rgb: "#6d28d9";
  --background-end-rgb: "#6d28d9";
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: "#6d28d9";
    --background-start-rgb: "#6d28d9";
    --background-end-rgb: "#6d28d9";
  }
} */

/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

body {
  width: 100%;
  height: 100%;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .text-balance {
    text-wrap: balance;
  }

  .hide-scrollbar-h {
    overflow-x: hidden;
  }
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/lato-light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/lato-regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/lato-bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("/fonts/lexend-semiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

html::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #9655f870 !important;
}

html::-webkit-scrollbar-track {
  background-color: #9655f870 !important;
  border-radius: 4px;
}

body::-webkit-scrollbar {
  width: 6px; /* Largura da barra de rolagem */
}

body::-webkit-scrollbar-track {
  background: #9655f870; /* Cor de fundo da trilha da barra de rolagem */
  border-radius: 8px; /* Arredondamento das bordas da trilha */
}

body::-webkit-scrollbar-thumb {
  background: #6d28d7; /* Cor da barra de rolagem */
  border-radius: 0px; /* Arredondamento das bordas da barra */
}

.bg-gradient-conf {
  background-image: linear-gradient(to bottom left, white, #9047ff);
}
.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.disable-scroll {
  overflow-y: hidden;
}

@media (min-width: 1024px) {
  #queridao {
    background-image: url("/images/queridao.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 45vw;
  }
}

#louro {
  background-image: url("/images/louro.png");
  background-size: 90% 15%;
  background-repeat: no-repeat;
  background-position: 5vw 45vw;
}

html,
body {
  overflow-x: hidden;
}
